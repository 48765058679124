@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

li {
    list-style-type: none
}

a {
    text-decoration: none;
    outline: none;
}

.contact-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Abel', sans-serif;
    background-color: #474748;
    /* border: 5px solid #3D3D3E; */
    padding: 2.5rem;
}

.first-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: white;
    font-size: 2rem;
    text-align: center;
}

.first-section h3 {
    color: rgb(226, 226, 226);
}

.instruction-text {
    background-color: rgb(100, 98, 97);
    border-radius: 5px;
    font-size: 1.2rem;
    max-width: 62.5rem;
    max-height: 25rem;
    padding: 2.5rem 4.25rem 2.5rem 4.25rem;
    box-shadow: 0 15px 13px -6px black;
    overflow: scroll;
    text-align: start;
}


.second-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: #3D3D3E; */
    margin-top: 30px;
    /* width: 100%;
    height: auto; */
    max-width: 81.25rem;
    box-shadow: 0 15px 13px -6px black;
    /* overflow: scroll; */
}

.Form-area {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-width: 17.75rem;
   
}

.photo-number-section {
    display: flex;
    font-size: 0.9rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: rgb(185, 185, 185);
}

.photo-number-section h1 {
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
}

.photo-number-section h2 {
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: x-large;
    text-align: center;
}

.callButton {
    background-color: green; 
    color: white; 
    font-size: 30px; 
    padding: 10px;
     /* padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 100px;
    padding-right: 100px;  */
    margin-top: 20px;
    /* margin-right: 20px; */
    /* margin-left: 20px; */
    border-radius: 5px;
    z-index: -1;
}

/* a:link {
    text-decoration: none;
} */



.contactImg {
    justify-content: center;
    /* height: 47.81rem; */
    /* width: 75%; */
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    height: auto;
    width: 100%;
    /* margin-top: -95px; */
    /* margin-bottom: 0.62rem; */
    /* padding: 20px; */
    padding-left: 20px;
    padding-right: 20px;
}

.third-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.87rem;
    text-align: center;
    text-transform: uppercase;
}

.third-section h1 {
    font-size: 5rem;
    color: white;
}

.third-section h2 {
    font-size: 3rem;
    color: rgb(185, 185, 185);
}

.third-section button {
    font-size: 2rem;
    border-radius: 5px;
    /* font-weight: bolder; */
    padding: 1rem;
    text-decoration: none;
    outline: none;
    color: #474748;
    background-color: #C5A83A;
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;
}

@media all and (max-width: 1000px) {
    .second-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* height: auto; */
    }

    .instruction-text {
        font-size: 1.1rem;
        /* max-width: 1000px; */
        padding: 0.5rem 2.0rem 0.5rem 2.0rem;
    }

    .contactImg {
        margin-top: 10px;
        justify-content: center;
        height: 25.81rem;
        width: 175%;
    }
}

@media all and (max-width: 515px) {

    .photo-number-section h1 {
        z-index: -1;
        margin: 0%;
    }

    .callButton {
        z-index: 0;
    }
}