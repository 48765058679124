@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ServiceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* width: 100%; */
  font-family: 'Abel', sans-serif;
  background-color: #474748;
  /* border: 5px solid #3d3d3e; */
  padding: 40px;
}

.offerdServ {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: 1000px;
  min-height: 500px;
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.6);
}

.shadowOfferedServ {
  max-height: 500px;
  padding: 0%;
  margin: 0%;
  /* border: 1px solid lightslategray; */
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-image: url('../img/1.-screen-printing.jpg');
  /* background-image: url('https://mereton.com.au/wp-content/uploads/2017/04/screenvssublimation.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 15px 13px -6px black;
}

.offerdServ h2 {
  font-size: 60px;
  border-bottom: 1px solid white;
  color: white;
}

.serviceListItems {
  display: flex;
  flex-direction: row;
  /* border: 1px solid white; */
  width: 100%;
  height: auto;
  /* text-align: start; */
  font-size: 20px;
  padding: 20px;
  color: white;
}

.serItem1 {
  /* border: 1px solid blue; */
  padding: 5px;
}

.serItem2 {
  /* border: 1px solid blue; */
  padding: 5px;
}

.serviceListItems li {
  cursor: pointer;
  font-size: 45px;
}

.serviceListItems li p {
  font-size: 20px;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
}

.servGrow:hover p {
  transform: scale(0.9);
  visibility: visible;
}

.shadowOfferedServ .turnAroundH {
  padding: 0%;
  margin: 0%;
  font-size: 45px;
  border: none;
}

/* .vectorFree {
  color: white;
  margin-top: 2px;
} */

a:link {
  color: white;
}

.typeDesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid white; */
  width: 100%;
  height: auto;
  max-width: 1000px;
  max-height: 600px;
  padding-top: 40px;
}

.svgBack {
  background-image: url('../img/doodles.png');
  width: 100%;
  height: auto;
  margin-right: 20px;
}

.iconsSvg {
  font-family: 'Abel', sans-serif;
  /* border: 1px solid white; */
  color: white;
  padding: 30px;
  /* margin-right: 10px; */
  width: auto;
  max-width: 500px;
  min-height: 500px;
  background-color: rgba(0, 0, 0, 0.6);
}

.iconsSvg p {
  font-size: 20px;
}

.iconsSvg h1 {
  font-size: 50px;
}

.graphicBack {
  background-image: url('https://images.unsplash.com/photo-1512295767273-ac109ac3acfa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80');
  width: 100%;
  height: auto;
  margin-left: 20px;
}

.grahicDesg {
  /* border: 1px solid white; */
  padding: 30px;
  /* margin-left: 10px; */
  text-align: center;
  color: white;
  font-size: 26px;
  max-width: 500px;
  min-height: 500px;
  background-color: rgba(0, 0, 0, 0.6);
}

.grahicDesg h1 {
  font-size: 50px;
}

.grahicDesg p {
  font-size: 20px;
  text-align: start;
}

@media all and (max-width: 1024px) {
  .offerdServ {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 550px;
    /* height: auto; */
    max-width: 1000px;
    min-height: 570px;
    margin-bottom: 30px;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .shadowOfferedServ {
    max-height: 570px;
    padding: 0%;
    margin: 0%;
    border: 1px solid lightslategray;
    /* background-color: rgba(0, 0, 0, 0.7); */
    /* background-image: url('https://mereton.com.au/wp-content/uploads/2017/04/screenvssublimation.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 15px 13px -6px black;
  }

  /* .turnAroundH {
    font-size: 1px;
  } */
}

@media all and (max-width: 1000px) {
  /* First Block of container */

  .offerdServ h2 {
    font-size: 40px;
    border-bottom: 1px solid white;
    color: white;
  }

  .serviceListItems {
    display: flex;
    flex-direction: column;
    /* border: 1px solid white; */
    width: 100%;
    height: auto;
    /* text-align: start; */
    font-size: 10px;
    padding: 10px;
    color: white;
  }

  .serItem1 {
    /* border: 1px solid blue; */
    padding: 5px;
  }

  .serItem2 {
    /* border: 1px solid blue; */
    padding: 5px;
  }

  .serviceListItems li {
    cursor: pointer;
    font-size: 30px;
  }

  .serviceListItems li p {
    font-size: 5px;
    padding: 5px;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
  }

  .shadowOfferedServ .turnAroundH {
    padding: 10px;
    margin: 0%;
    font-size: 25px;
    border: none;
  }

  /* Second Block of container */

  .typeDesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 900px;
    /* padding: 10px; */
  }

  .svgBack {
    background-image: url('../img/doodles.png');
    margin-left: 15px;
    margin-bottom: 10px;
  }

  .iconsSvg {
    font-family: 'Abel', sans-serif;
    /* border: 1px solid white; */
    color: white;
    width: auto;
    max-width: 350px;
    min-height: 400px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .iconsSvg p {
    font-size: 15px;
  }

  .iconsSvg h1 {
    font-size: 30px;
  }

  .graphicBack {
    background-image: url('https://images.unsplash.com/photo-1512295767273-ac109ac3acfa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80');
    margin-right: 25px;
    margin-top: 10px;
  }

  .grahicDesg {
    /* border: 1px solid white; */
    /* padding: 30px; */
    /* margin-left: 10px; */
    /* text-align: center; */
    color: white;
    font-size: 26px;
    max-width: 500px;
    min-height: 400px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .grahicDesg h1 {
    font-size: 30px;
  }

  .grahicDesg p {
    font-size: 15px;
    /* text-align: start; */
  }
}

@media all and (max-width: 320px) {
  .serviceListItems li {
    cursor: pointer;
    font-size: 20px;
  }

  .serviceListItems li p {
    font-size: 5px;
    padding: 2.5px;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
  }

  .iconsSvg p {
    font-size: 15px;
  }

  .iconsSvg h1 {
    font-size: 25px;
  }

  .grahicDesg h1 {
    font-size: 25px;
  }

  .grahicDesg p {
    font-size: 15px;
    /* text-align: start; */
  }

  .servFormContainer-form {
    max-width: 310px;
  }
}
