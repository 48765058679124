@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgba(0,0,0, 0.1); */
}

.HomeContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-family: 'Abel', sans-serif;
  background-color: #474748;
  /* border: 5px solid #3d3d3e; */
  padding: 40px;
  /* padding: 80px; */
  animation: fadeInAnimation ease 3s; 
            animation-iteration-count: 1; 
            animation-fill-mode: forwards; 
}

@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% { 
      opacity: 1; 
  } 
} 



.introduction-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1600;
  border: 2px solid #3d3d3e;
  border-radius: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 100px;
  padding-right: 100px;
  background: -webkit-linear-gradient(
      45deg,
      rgba(234, 85, 92, 0.6) 10%,
      transparent 10%,
      transparent 20%,
      rgba(225, 200, 157, 0.6) 20%,
      rgba(225, 200, 157, 0.6) 30%,
      transparent 30%,
      transparent 40%,
      rgba(117, 188, 179, 0.6) 40%,
      rgba(117, 188, 179, 0.6) 50%,
      transparent 50%,
      transparent 60%,
      rgba(191, 128, 12, 0.6) 60%,
      rgba(191, 128, 12, 0.6) 70%,
      transparent 70%,
      transparent 80%,
      rgba(92, 77, 83, 0.6) 80%,
      rgba(92, 77, 83, 0.6) 90%,
      transparent 90%,
      transparent 100%
    ),
    -webkit-linear-gradient(135deg, rgba(171, 0, 0, 0.6) 10%, transparent 10%, transparent
          20%, rgba(241, 241, 191, 0.6) 20%, rgba(241, 241, 191, 0.6) 30%, transparent
          30%, transparent 40%, rgba(51, 79, 76, 0.6) 40%, rgba(51, 79, 76, 0.6)
          50%, transparent 50%, transparent 60%, rgba(191, 128, 12, 0.6) 60%, rgba(
            191,
            128,
            12,
            0.6
          )
          70%, transparent 70%, transparent 80%, rgba(241, 241, 191, 0.6) 80%, rgba(
            241,
            241,
            191,
            0.6
          )
          90%, transparent 90%, transparent 100%);
          
}



.screenHomeImg {
  /* background-image: url("https://images.unsplash.com/photo-1456456496250-d5e7c0a9b44d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80"); */
  height: auto;
  /* height: 700px; */
  width: 100%;
  max-width: 1600px;
  object-fit: cover;
  box-shadow: 0 15px 13px -6px black;
}



.title-location {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}


.title-company {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}


/* .introduction-title img { */
  /* height: auto; */
  /* height: 700px; */
  /* width: 100%; */
  /* width: 1200px; */
  /* object-fit: cover; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-blend-mode: darken; */
  /* box-shadow: 0 15px 13px -6px black; */
/* } */

.introduction-title h3 {
  position: absolute;
  top: 300px;
  left: 200px;
  font-size: clamp(25px, 5vw, 70px);
  /* font-size: 60px; */
  /* color: rgb(253, 255, 255); */
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.0002px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  /* color: rgb(255, 255, 255); */
}

.introduction-title h2 {
  position: absolute;
  top: 450px;
  left: 200px;
  padding-bottom: 2px;
  border-bottom: 1px solid lightslategray;
  font-size: clamp(25px, 5vw, 80px);
  /* font-size: 70px; */
  /* color: rgb(255, 255, 255); */
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.0002px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  /* color: rgb(255, 255, 255); */
}





/* ********* New buttons prototype */

.intro-btns {
  position: absolute;
  display: flex;
  overflow: hidden;
  top: 650px;
  left: 200px;
}

.introBtn-one {
  font-size: clamp(15px, 5vw, 45px);
  /* font-size: 45px; */
  font-family: 'Abel', sans-serif;
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  outline: none;
  /* overflow: hidden; */
  padding: 10px;
}

.introBtn-one a {
  position: relative;
  transition: all .45s ease-Out;
}

.introBtn-two {
  font-size: clamp(15px, 5vw, 45px);
  /* font-size: 45px; */
  font-family: 'Abel', sans-serif;
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  outline: none;
  overflow: hidden;
  padding: 10px;
  margin-left: 10px;
}

.introBtn-two a {
  position: relative;
  transition: all .45s ease-Out;
}
/* 
.translateOne {
  transform: rotate(50deg);
  width: 100%;
  height: 250%;
  left: -400px;
  top: -30px;
  background: #BFC0C0;
  position: absolute;
  transition: all .3s ease-Out;
}


.translateTwo {
  transform: rotate(50deg);
  width: 100%;
  height: 250%;
  left: 450px;
  top: -30px;
  background: #BFC0C0;
  position: absolute;
  transition: all .3s ease-Out;
}

.introBtn-one:hover .translateOne{
  left: 0;
  cursor: pointer;
}

.introBtn-two:hover .translateTwo {
  left: 0;
  cursor: pointer;
} */

.introBtn-one:hover a {
  color: #2D3142;
}

.introBtn-two:hover a {
  color: #2D3142;
}

/* ********* New buttons prototype */





.home-specialze {
  background-image: url('../img/boxOfShirts.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #3d3d3e;
  height: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.boxPicImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0%;
  margin: 0%;
  width: 100%;
  height: auto;
}

.homePicSection {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.home-specialze h2 {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 300px;
  margin-top: 10px;
  font-size: 40px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border-bottom: 1px solid snow;
}

.spec-pic {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* margin: 40px; */
  padding: 50px;
}

.homecontentFig {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.figCap {
  position: relative;
  padding: 0;
  top: 250px;
  font-size: 30px;
  background-color: black;
  width: 100%;
  max-width: 200px;
  /* margin-left: 80px; */
  color: white;
}

.spec-pic img {
  height: auto;
  width: 100%;
  max-width: 300px;
  /* padding: 10px; */
  margin: 20px;
  border: 2px solid lightslategray;
  border-radius: 5px;
  /* cursor: pointer; */
  /* backface-visibility: hidden; */
  box-shadow: 0 15px 13px -6px black;
  transition: transform 0.2s;
  /* opacity: 1; */
}

.spec-pic .spImg:hover {
  transform: scale(1.1);
  opacity: 0.3;
}



@media all and (max-width: 1366px) {
  .HomeContent {
    margin: 0%;
    padding: 20px;
    /* width: 1364; */
    /* max-width: 1366; */
  }

  .introduction-title {
    margin: 0%;
    padding: 10px;
    /* width: 1200; */
    /* max-width: 1360; */
    align-items: center;
    text-align: start;
  }

  /* .introduction-title h3 {
    text-align: start;
  } */

  
  /* .introduction-title img {
    height: 600px;
    width: 750px;
  } */

  .introduction-title img {
    height: auto;
    width: 100%;
  }

  .cms-section {
    width: auto;
  }

  .cms-section {
    width: 100%;
  }

  .home-specialze {
    width: 100%;
    height: auto;
    flex-direction: column;
  }

  .home-specialze .spec-pic {
    flex-direction: column;
  }
}

@media all and (max-width: 1024px) {
  .HomeContent {
    margin: 0%;
    padding: 10px;
    width: 100%;
    max-width: 1024px;
  }

  .introduction-title {
    margin: 0%;
    padding: 10px;
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .introduction-title img {
    height: auto;
    width: 100%;
  }
  /* .introduction-title img {
    height: 400px;
    width: 650px;
  } */

  .introduction-title h3 {
    /* position: absolute; */
    top: 300px;
    left: 100px;
    font-size: 50px;
    /* color: rgb(225, 241, 255); */
    /* color: rgb(255, 255, 255); */
  }
  
  .introduction-title h2 {
    /* position: absolute; */
    top: 400px;
    left: 100px;
    /* padding-bottom: 2px; */
    /* border-bottom: 1px solid lightslategray; */
    font-size: 60px;
    /* color: rgb(225, 241, 255); */
    /* color: rgb(255, 255, 255); */
  }

  .intro-btns {
    top: 550px;
    left: 100px;
  }
  

  .cms-section {
    width: auto;
  }

  .cms-section {
    width: 100%;
  }

  .home-specialze {
    width: 100%;
    height: auto;
    flex-direction: column;
  }

  .home-specialze .spec-pic {
    flex-direction: column;
  }
}

@media all and (max-width: 812px) {
  .HomeContent {
    margin: 0%;
    padding: 10px;
  }

  .introduction-title {
    margin: 0%;
    padding: 10px;
    height: auto;
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .introduction-title img {
    height: auto;
    width: 100%;
  }

  .introduction-title h3 {
    top: 220px;
    left: 50px;
    font-size: 40px;
  }
  
  .introduction-title h2 {
    top: 300px;
    left: 50px;
    font-size: 40px;
  }

  .intro-btns {
    top: 500px;
    left: 50px;
  }

  .cms-section {
    width: 100%;
  }

  .cms-section article {
    height: 300px;
    width: 250px;
    overflow: scroll;
  }

  .home-specialze {
    width: 100%;
    height: auto;
    flex-direction: column;
  }

  .home-specialze .spec-pic {
    flex-direction: column;
  }
}

@media all and (max-width: 667px) {
  .introduction-title h3 {
    top: 220px;
    left: 50px;
    font-size: 30px;
  }
  
  .introduction-title h2 {
    top: 300px;
    left: 50px;
    font-size: 40px;
  }

  .intro-btns {
    top: 450px;
    left: 50px;
  }
}


@media all and (max-width: 568px){

  .introduction-title h3 {
    top: 220px;
    left: 50px;
    font-size: 20px;
  }
  
  .introduction-title h2 {
    top: 270px;
    left: 50px;
    font-size: 30px;
  }

  .intro-btns {
    top: 350px;
    left: 50px;
  }

}

@media all and (max-width: 414px) {
  .introduction-title h3 {
    top: 210px;
    left: 50px;
    font-size: 20px;
    /* font-size: clamp(25px, 5vw, 30px) */
  }

  .introduction-title h2 {
    top: 270px;
    left: 50px;
    font-size: 20px;
    /* font-size: clamp(25px, 5vw, 10px) */
  }
  .intro-btns {
    top: 320px;
    left: 50px;
  }
}


@media all and (max-width: 375px) {
  .introduction-title img {
    height: auto;
    width: 100%;
  }
  /* .introduction-title img {
    height: 400px;
    width: 650px;
  } */

  .introduction-title h3 {
    top: 220px;
    left: 50px;
    font-size: 15px;
  }
  
  .introduction-title h2 {
    top: 260px;
    left: 50px;
    font-size: 20px;
  }
}

@media all and (max-width: 320px) {
  
  .introduction-title h3 {
    /* position: static; */
    top: 200px;
    left: 50px;
    font-size: 15px;
    /* z-index: -1; */
  }
  
  .introduction-title h2 {
    /* position: static; */
    top: 230px;
    left: 50px;
    font-size: 15px;
    /* z-index: -1; */
  }

  .intro-btns {
    top: 270px;
    left: 50px;
  }
}