@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

 * { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 

footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* max-width: 1200px; */
    font-family: 'Abel', sans-serif;
    /* border: 5px solid lightslategray; */
    padding: 50px;
    height: 170px;
    background-color: black;
  }
  
  footer .footer-img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin-left: 50px; */
    /* width: fit-content; */
  }
  
  footer img {
    /* display: flex; */
    /* align-content: flex-end; */
    /* align-items: flex-end; */
    height: 160px;
    width: 175px;
    
  }
  
  footer .social-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 200px;
    /* color: white; */
    background-color: black;
  }
  
  footer .facebook {
    /* background-color: white; */
    color: #C5A83A;
    cursor: pointer;
  }
  footer .instgram {
    /* background-color: white; */
    color: #C5A83A;
    cursor: pointer;
  }
  
  footer h6 {
    text-align: center;
    color: white;
  }
  
  footer button {
    color: black;
    background-color: #C5A83A;
    border-radius: 10px;
    outline: none;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Bowlby One SC', cursive;
  }

  @media all and (max-width: 800px) {

    footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* max-width: 1200px; */
        font-family: 'Abel', sans-serif;
        /* border: 5px solid lightslategray; */
        padding: 10px;
        height: 270px;
        background-color: black;
      }
    
    footer .footer-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* margin-left: 50px; */
      /* width: fit-content; */
    }
    
    footer img {
      /* display: flex; */
      /* align-content: flex-end; */
      /* align-items: flex-end; */
      height: 90px;
      width: 95px;
      
    }
    
    footer .social-icons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 150px;
      /* color: white; */
      background-color: black;
    }
    
    footer .facebook {
      /* background-color: white; */
      color: #C5A83A;
      /* cursor: pointer; */
    }
    footer .instgram {
      /* background-color: white; */
      color: #C5A83A;
      /* cursor: pointer; */
    }
    
    footer h6 {
      text-align: center;
      color: white;
    }
    
    footer button {
      color: black;
      background-color: #C5A83A;
      border-radius: 10px;
      outline: none;
      padding: 5px;
      cursor: pointer;
      font-size: 20px;
      /* font-size: 10px; */
      font-family: 'Bowlby One SC', cursive;
    }
  }