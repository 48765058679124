@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgba(0,0,0, 0.1); */
}

.AboutMainPage {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-family: 'Abel', sans-serif;
  background-color: #474748;
  /* border: 5px solid #3d3d3e; */
  padding: 80px;
}

.picAboutSection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* border: 1px solid lightslategray; */
  width: 1300px;
  height: 1000px;
  padding-top: 25px;
  padding-left: 60px;
  padding-right: 60px;
  /* margin: 15px; */
}

.main-PicArea {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* border: 1px solid lightslategray; */
  /* width: 550px; */
  height: 900px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.picArea-1 {
  /* background-image: url('../img/boxOfShirts.jpg'); */
  background-image: url('../img/toyMask.jpg');
  /* background-color: rgba(0,0,0,0.5); */
  background-repeat: no-repeat;
  background-size: cover;
  /* object-fit: cover; */
  width: 500px;
  height: 418px;
  /* border: 1px solid lightslategray; */
  /* margin-top: 10px; */
}

/* .picArea-1 img{
    width: 498px;
    height: 418px;
    object-fit: cover;
    /* opacity: 0.2; */

/* } */

.toyMask-Caption {
  background-color: rgba(0, 0, 0, 0.5);
  /* position: relative; */
  width: 100%;
  height: 100%;
  /* max-height: 440px; */
  /* top: -00px; */
  padding: 30px;
  color: rgb(238, 236, 236);
  font-size: 60px;
  text-align: start;
}

.picArea-2 {
  background-image: url('../img/weather.png');
  /* background-color: rgba(0,0,0,0.5); */
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 500px;
  height: 418px;
  /* border: 1px solid lightslategray; */
  /* margin-bottom: 20px; */
}

/* .picArea-2 img{
    width: 498px;
    height: 428px;
    color: rgb(19, 19, 19);
} */

.skulls-Caption {
  position: relative;
  top: 20px;
  padding: 30px;
  text-align: start;
  color: rgb(19, 19, 19);
  font-size: 30px;
}

.skulls-Caption h2 {
  font-size: 55px;
}

.skulls-Caption p {
  padding-top: 20px;
  font-size: 60px;
}

.usAbout {
  /* border: 1px solid lightslategray; */
  width: 550px;
  height: 900px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 25px;
  background-color: rgb(19, 19, 19);
}

.AboutUs-Contents {
  padding: 30px;
}

.AboutUs-Contents h1 {
  color: white;
  font-size: 60px;
}

.AboutUs-Contents h3 {
  color: rgb(238, 223, 15);
  font-size: 35px;
}

.AboutUs-Contents article {
  color: rgb(199, 199, 199);
  font-size: 24px;
}

.photo-Gall {
  /* border: 1px solid lightslategray; */
  width: 100%;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 30px;
}

.message-us {
  font-size: 60px;
  padding: 15px;
  margin-left: 40px;
}

.AboutMainPage button {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  font-size: 30px;
  padding: 10px;
  box-shadow: 0 15px 13px -6px black;
  border-radius: 5px;
  background-color: rgb(206, 164, 27);
  margin-left: 40px;
}

/* @media all and (max-width: 1366px) {
  
} */

@media all and (max-width: 1024px) {
  .AboutMainPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0%;
    padding-bottom: 30px;
    padding-left: 0%;
    padding-right: 0%;
  }

  .picAboutSection {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 345px;
    height: auto;
    /* background-color: #3D3D3E; */
    padding: 0%;
    margin: 0%;
  }

  .main-PicArea {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 100%;
    height: auto;
    padding: 0%;
    margin: 15px;
    /* margin-top: 0; */
    /* background-color: aliceblue; */
  }

  .picArea-1 {
    width: 600px;
    height: 600px;
    margin: 15px;
    /* margin-right: 12px; */
  }

  /* .picArea-1 img{
        width: 328px;
        height: 298px;
        /* margin-right: 12px; */
  /* opacity: 0; */

  /* } */

  .toyMask-Caption {
    /* position: relative; */
    /* top: -110px; */
    padding: 20px;
    /* color: rgb(238, 236, 236); */
    font-size: 90px;
    text-align: start;
  }

  .picArea-2 {
    width: 600px;
    height: 600px;
    margin: 15px;
    /* margin-right: 12px; */
  }

  /* .picArea-2 img{
        width: 328px;
        height: 298px;
        /* margin-right: 12px; */
  /* opacity: 0; */
  /* }  */

  .skulls-Caption {
    /* position: relative; */
    /* top: -310px; */
    padding: 30px;
    text-align: start;
    color: rgb(19, 19, 19);
  }

  .skulls-Caption h2 {
    font-size: 90px;
  }

  .skulls-Caption p {
    /* padding-top: 20px; */
    font-size: 40px;
  }

  /* About Mobile */

  .usAbout {
    /* border: 1px solid lightslategray; */
    width: 600px;
    height: 600px;
    margin: 0%;
    margin-bottom: 10px;
    padding: 0%;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    /* margin-left: 10px; */
    /* margin-right: 25px; */
    background-color: rgb(19, 19, 19);
  }

  /* .AboutUs-Contents {
        padding: 30px;
    } */

  .AboutUs-Contents h1 {
    color: white;
    font-size: 90px;
  }

  .AboutUs-Contents h3 {
    color: rgb(238, 223, 15);
    font-size: 30px;
  }

  .AboutUs-Contents article {
    color: rgb(199, 199, 199);
    font-size: 20px;
  }

  .photo-Gall {
    /* width: 530px;
    height: 530px; */
    /* justify-content: center; */
    /* margin: 0%; */
    /* padding: 0%; */
    margin-top: 10px;
    /* margin-bottom: 15px; */
    /* margin-left: 100px; */
    /* margin-right: 500px; */
  }
}

/* @media all and (max-width: px)  */

@media all and (max-width: 812px) {
  .AboutMainPage {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 0%;
    padding-bottom: 30px;
    padding-left: 0%;
    padding-right: 0%;
  }

  .picAboutSection {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 345px;
    height: 1400px;
    /* background-color: #3D3D3E; */
    padding: 0%;
    margin: 0%;
  }

  .main-PicArea {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 335px;
    height: 700px;
    padding: 0%;
    margin: 0%;
    /* background-color: aliceblue; */
  }

  .picArea-1 {
    width: 310px;
    height: 300px;
    /* margin-right: 12px; */
  }

  /* .picArea-1 img{
        width: 328px;
        height: 298px; */
  /* margin-right: 12px; */
  /* opacity: 0; */

  /* } */

  .toyMask-Caption {
    /* position: relative; */
    /* top: -10px; */
    padding: 30px;
    /* color: rgb(238, 236, 236); */
    font-size: 40px;
    text-align: start;
  }

  .picArea-2 {
    width: 310px;
    height: 300px;
    /* margin-right: 12px; */
  }

  /* .picArea-2 img{
        width: 328px;
        height: 298px; */
  /* margin-right: 12px; */
  /* opacity: 0; */
  /* } */

  .skulls-Caption {
    position: relative;
    top: -10px;
    padding: 30px;
    text-align: start;
    color: rgb(19, 19, 19);
  }

  .skulls-Caption h2 {
    font-size: 42px;
  }

  .skulls-Caption p {
    /* padding-top: 20px; */
    font-size: 20px;
  }

  /* About Mobile */

  .usAbout {
    /* border: 1px solid lightslategray; */
    width: 310px;
    height: 630px;
    margin: 0%;
    padding: 0%;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    /* margin-left: 10px; */
    /* margin-right: 25px; */
    background-color: rgb(19, 19, 19);
  }

  /* .AboutUs-Contents {
        padding: 30px;
    } */

  .AboutUs-Contents h1 {
    color: white;
    font-size: 50px;
  }

  .AboutUs-Contents h3 {
    color: rgb(238, 223, 15);
    font-size: 30px;
  }

  .AboutUs-Contents article {
    color: rgb(199, 199, 199);
    font-size: 14px;
  }

  .photo-Gall {
    /* width: 310px;
    height: 375px; */
    margin: 0%;
    padding: 0%;
    margin-top: 10px;
    /* margin-bottom: 15px; */
    /* margin-left: 15px; */
    /* margin-right: 30px; */
  }

  .message-us {
    text-align: center;
    font-size: 60px;
    padding: 15px;
    margin-right: 20px;
  }

  .AboutMainPage button {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    font-size: 30px;
    padding: 10px;
    margin-right: 30px;
  }
}

@media all and (max-width: 320px) {
  .AboutMainPage {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 0%;
    padding-bottom: 30px;
    padding-left: 0%;
    padding-right: 0%;
  }

  .picAboutSection {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    /* width: 345px; */
    height: 1400px;
    /* background-color: #3D3D3E; */
    padding: 0%;
    margin: 0%;
  }

  .main-PicArea {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 320px;
    /* width: 320px; */
    height: 700px;
    padding: 0%;
    margin: 0%;
    /* background-color: aliceblue; */
  }

  .picArea-1 {
    width: 310px;
    height: 300px;
    /* margin-right: 12px; */
  }

  /* .picArea-1 img{
        width: 328px;
        height: 298px; */
  /* margin-right: 12px; */
  /* opacity: 0; */

  /* } */

  .toyMask-Caption {
    /* position: relative; */
    /* top: -10px; */
    padding: 30px;
    /* color: rgb(238, 236, 236); */
    font-size: 40px;
    text-align: start;
  }

  .picArea-2 {
    width: 310px;
    height: 300px;
    /* margin-right: 12px; */
  }

  /* .picArea-2 img{
        width: 328px;
        height: 298px; */
  /* margin-right: 12px; */
  /* opacity: 0; */
  /* } */

  .skulls-Caption {
    position: relative;
    /* top: -310px; */
    padding: 30px;
    text-align: start;
    color: rgb(19, 19, 19);
  }

  .skulls-Caption h2 {
    font-size: 42px;
  }

  .skulls-Caption p {
    /* padding-top: 20px; */
    font-size: 20px;
  }

  /* About Mobile */

  .usAbout {
    /* border: 1px solid lightslategray; */
    width: 310px;
    height: 630px;
    margin: 0%;
    padding: 0%;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    /* margin-left: 10px; */
    /* margin-right: 25px; */
    background-color: rgb(19, 19, 19);
  }

  /* .AboutUs-Contents {
        padding: 30px;
    } */

  .AboutUs-Contents h1 {
    color: white;
    font-size: 50px;
  }

  .AboutUs-Contents h3 {
    color: rgb(238, 223, 15);
    font-size: 30px;
  }

  .AboutUs-Contents article {
    color: rgb(199, 199, 199);
    font-size: 14px;
  }

  .photo-Gall {
    /* width: 310px;
    height: 375px;  */
    margin: 0%;
    padding: 0%;
    margin-top: 10px;
    /* margin-bottom: 15px; */
    /* margin-left: 15px; */
    /* margin-right: 30px; */
  }

  .message-us {
    text-align: center;
    font-size: 60px;
    padding: 15px;
    margin-right: 20px;
  }

  .AboutMainPage button {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    font-size: 30px;
    padding: 10px;
    margin-right: 30px;
  }
}
