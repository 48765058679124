@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgba(0,0,0, 0.1); */
  
}

.nav-links li {
    list-style-type: none;
    text-decoration: none;
  }
  
.nav-links li a:link, a:visited  {
    text-decoration: none;
    color: #fff;
  }

.navbarNav {
    display: flex;
    justify-content: space-between;
    min-height: 16vh;
    align-items: center;
    /* padding: 20px; */
    padding-left: 40px;
    padding-right: 40px;
    /* font-family: monospace; */
    font-family: 'Abel', sans-serif;
    /* background-color:lightslategray; */
    background-color: black;
    color: white;
    /* z-index: 1; */
  }
  
  .navbarNav .ink-logo {
  
    /* new */
    margin-right: 600px;
    /* new */
    font-size: 22px;
    font-weight: 700;
    text-transform:  uppercase;
  }
  
  .navbarNav .nav-links {
    display: flex;
    justify-content: space-around;
    width: 30%;
  }
  
  .navbarNav .nav-links a {
    font-size: 30px;
    /* font-size: 18px; */
    font-weight: normal;
    cursor: pointer;
  }

 
  
  .navbarNav .nav-links a:hover {
    border-bottom: 1px solid #fff;
    
  }
  
  .navbarNav .burger {
    font-size: 25px;
    display: none;
    cursor: pointer;
  }
  
   .navbarNav img {
    height: 100px;
    width: 200px;
    /* margin-right: 15px; */
  }

  @media all and (max-width: 1100px) {
    .navbarNav .ink-logo{
  
      
      margin-right: 100px;
      
      font-size: 22px;
      font-weight: 700;
      text-transform:  uppercase;
    }
  
    .navbarNav .nav-links {
      display: flex;
      justify-content: space-around;
      width: 50%;
    }
  }

  @media all and (max-width: 800px) {
    .navbarNav .burger {
        display: block;
      }
    
      .navbarNav .nav-links {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        /* height: 40vh; */
        height: 120vh;
        background-color: rgb(141, 150, 158);
        padding: 20px;
        width: 60%;
        transform: translateX(-500px);
        transition: 0.5s ease-in-out;
        z-index: 1;
      }
    
      .navbarNav .nav-links li {
        padding: 25px;
        border-bottom: 1px solid #ccc;
      }
    
      .navbarNav .nav-links li:last-of-type {
        border-bottom: 0px solid #ccc;
      }
    
      .navbarNav .nav-links a:hover {
        border-bottom: 0px solid #fff;
        
      }
    
      .navbarNav img {
        height: 80px;
        width: 180px;
        margin-right: 5px;
      }
  }

  @media all and (max-width: 320px) {
    .navbarNav .nav-links {
      /* font-size: 10px; */
      padding: 15px;
      width: 60%;
    }

    .navbarNav img {
      height: 60px;
      width: 140px;
      margin-right: 5px;
    }
  }