@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgb(107, 107, 107); */
}

.photoGalleryContainer {
  /* background-color: rgb(107, 107, 107); */
  /* border: 5px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  /* padding: 20px; */
  font-family: 'Abel', sans-serif;
}

.secondaryContainerPhoto {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pictureIndiv {
  /* border: 1px solid white; */
  /* width: 100%; */
  width: 18.75rem;
  max-width: 18.75rem;
  height: auto;
  max-height: 18.75rem;
  /* object-fit: cover; */
  margin: 5px;
  /* z-index: 1; */
  /* background-color: blanchedalmond; */
}



.galleryTitle {
  font-size: 55px;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.srcPics {
  width: 18.75rem;
  max-width: 18.75rem;
  height: 18.75rem;
  max-height: 18.75rem;
  object-fit: cover;
}

.srcPics:hover {
  cursor: pointer;
  /* z-index: -1; */
  opacity: 0.3;
  /* transition: transform 0.9s; */
  background-color: rgba(0,0,0,0.5);
}

.photoShadowInsta {
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* max-width: 18.75rem; */
  /* height: auto; */
  /* max-height: 18.75rem; */
}

.pictureIndiv:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  opacity: 1;
}
