* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }



.cms-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* justify-content: space-around; */
    align-items: center;
    border: 1px solid #3d3d3e;
    padding: 80px;
    margin-top: 80px;
    height: 100%;
    width: 100%;
    /* max-width: auto; */
    color: #ced1d6;
    font-size: 35px;
    background-color: rgba(0,0,0,0.8);
    /* background-color: black; */
    /* background-blend-mode: darken; */
    background-blend-mode: hue;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: cover;
  }

  .cmsSectionHeader {
    /* background-color: rgba(0,0,0,0.5); */
    border-bottom: 1px solid grey;
    color: #d5d7db
  }


  .cmsPhotoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .cmsPhotoFetch {
    width: 18.75rem;
  max-width: 18.75rem;
  height: 18.75rem;
  max-height: 18.75rem;
    margin: 1.25rem;
    border: 2px solid lightslategray;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    border-radius: 0.3125rem;
  }

  @media all and (max-width: 1690px) {
    .cmsPhotoFetch {
       /* height: 100%; */
      max-height: 16.75rem;
      /* width: 100%; */
      max-width: 16.75rem; 
      margin: 1rem;
      /* border: 2px solid lightslategray; */
      /* background-repeat: no-repeat; */
      /* background-size: cover; */
      /* object-fit: cover; */
      /* border-radius: 0.3125rem;  */
    }

  }

  @media all and (max-width: 1366px) {
    .cmsPhotoFetch {
      max-height: 17.75rem;
      max-width: 17.75rem; 
      margin: 0.4rem;
    }

  }

  @media all and (max-width: 1024px) {
    .cmsPhotoFetch {
       /* height: 100%; */
      max-height: 8.75rem;
      /* width: 100%; */
      max-width: 8.75rem; 
      margin: 0.2rem;
      /* border: 2px solid lightslategray; */
      /* background-repeat: no-repeat; */
      /* background-size: cover; */
      /* object-fit: cover; */
      /* border-radius: 0.3125rem;  */
    }

  }

  @media all and (max-width: 667px) {
    .cmsPhotoFetch {
      max-height: 8.75rem;
      min-width: 7.75rem; 
      max-width: 7.75rem; 
      margin: 1rem;
    }

  }

  @media all and (max-width: 568px) {
    .cms-section {
      padding: 40px;
      margin-top: 80px;
    }

    .cmsSectionHeader {
      text-align: center;
      font-size: 45px;
    }

    .body_Textbody {
      margin-left: 25px;
      font-size: 25px;
    }

    .cmsPhotoContainer {
      top: -100px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      height: auto;
    }

    .cmsPhotoFetch {
      max-height: 14.75rem;
      max-width: 14.75rem; 
      margin: 1rem;
    }

  }

  @media all and (max-width: 375px) {
    .cms-section {
      padding: 40px;
      margin-top: 80px;
    }

    .cmsSectionHeader {
      text-align: center;
      font-size: 30px;
    }

    .body_Textbody {
      margin-left: 25px;
      font-size: 25px;
    }

    .cmsPhotoContainer {
      top: -100px;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: auto;
    }

    .cmsPhotoFetch {
      max-height: 14.75rem;
      max-width: 14.75rem; 
      margin: 1rem;
    }

  }


  @media all and (max-width: 320px) {
    .cms-section {
      padding: 20px;
      margin-top: 80px;
    }

    .cmsSectionHeader {
      text-align: center;
      font-size: 30px;
    }

    .body_Textbody {
      margin-left: 25px;
      font-size: 25px;
    }

    .cmsPhotoContainer {
      top: -100px;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: auto;
    }

    .cmsPhotoFetch {
      max-height: 14.75rem;
      max-width: 14.75rem; 
      margin: 1rem;
    }

  }

  