@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #474748; */
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #474748;
  text-align: center;
  /* max-width: 900px; */
  width: 100%;
  height: 100vh;
  border: 1px solid lightslategray;
  font-family: 'Abel', sans-serif;
  height: 500px;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 900px;
  width: 100%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  padding: 10px;
}

.form-inputs .company {
  width: 275%;
  text-decoration: none;
  color: rgb(179, 179, 179);
  outline: none;
  border-radius: 2px;
  border: 1px solid rgb(245, 214, 41);
  background: black;
  padding: 5px;
}

.pesonal-inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 275%;
}

.pesonal-inputs .firstName {
  width: 275%;
  text-decoration: none;
  color: rgb(179, 179, 179);
  outline: none;
  border-radius: 2px;
  border: 1px solid rgb(245, 214, 41);
  background: black;
  padding: 5px;
}

.pesonal-inputs .lastName {
  width: 275%;
  text-decoration: none;
  color: rgb(179, 179, 179);
  outline: none;
  border-radius: 2px;
  border: 1px solid rgb(245, 214, 41);
  background: black;
  padding: 5px;
}

.formBack {
  background-color: rgb(194, 199, 201);
  width: 0 auto;
}

.servFormAll {
  margin-top: 40px;
  /* box-shadow: 0 15px 13px -6px black; */
  box-shadow: 0 30px 30px -6px black;
}
